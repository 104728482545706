module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-150262253-1","head":false,"anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://century21financial.co.nz"},
    },{
      plugin: require('../node_modules/gatsby-theme-jules/gatsby-browser.js'),
      options: {"plugins":[],"siteMetadata":{"siteUrl":"https://century21financial.co.nz","title":"Century 21 Financial","description":"Century 21 Financial Is The Gold Standard Mortgage Broker in New Zealand. Click Here To See How We Can Help","author":"@power_board","image":"https://century21financial.co.nz/images/defaultImage.jpg"},"trackingId":"UA-150262253-1","datocms":{"apiToken":"37530e4648c14e73cb889de1ff6602","previewMode":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Century21 Financial","short_name":"C21Finance","start_url":"/","background_color":"#f7f0eb","theme_color":"#BEAF87","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c0576e9b9c16cc5c0849e776ec5d931e"},
    }]
